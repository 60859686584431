import { Link, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';

import {
  IconForecast,
  IconAdmin,
  IconKS,
  IconNbAsk,
  IconNbBk,
  IconNbDash,
  IconNbLogout,
  IconCalendarNb,
  IconPayrollNb,
} from 'components/Icons';

import { useHoneLocationUsers } from 'components/HoneLocationUsers';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { HoneReportTimeframes, HoneReportTypes, MENU_ICONS } from '../../../constants';

import { useSidenavStore } from 'hooks/useSidenavStore';

import { reportTitleToUrl, abilityCan } from 'lib/utils';
import { useLocationsStore } from 'hooks/useLocationsStore';

import { UIAskRequestsTitles } from 'types/askcustomer';
import { useReportsQuery } from 'hooks/reports/useReportsQuery';
import {
  HoneAbilityActions,
  HoneAbilitySubjects,
  LocationConfig,
  LocationLinks,
  ReportTimeframes,
} from '@hone-automation/common';
import { useAskRequestsPendingCount } from 'hooks/useAskRequestsPendingCount';

import SideNavAccrualsLink from './SideNavAccrualsLink';
import SideNavIcon from './SideNavIcon';
import { useAuthContext } from 'context/useAuthContext';

import { useAskRequestsCountQuery } from 'hooks/useAskRequestsCountQuery';
import { Can } from '@casl/react';
import { isEmpty } from 'lodash';
import { format } from 'date-fns-tz';
import { endOfMonth, startOfMonth } from 'date-fns';

const closedTitles = {
  'P&L': 'P&L',
  'Manager Dashboard': 'Manager',
  'Balance Sheet': 'Balance',
  'Cash Flow': 'Cash',
  'YTD Monthly Income Statement': 'YTD',
  Forecast: 'FST',
  Bookkeeper: 'Accountant',
  Dashboard: 'Home',
  'Ask Requests': 'Requests',
  'AP Aging': 'Payables',
  'Journal Entries': 'Entries',
  'Bkpr Admin': 'Admin',
  Payroll: 'Payroll',
  Calendar: 'Calendar',
};

const isBeta = (value: string) => {
  return ['none'].includes(value); //"Forecast", "Ask Requests", "AP Aging", "Journal Entries"
};

function Sidenav() {
  const { data: askRequestsCount } = useAskRequestsCountQuery();
  const { currentLocationAbility } = useHoneLocationUsers();

  const { enableForecasting, enableNewPlReportModal } = useFlags();

  const auth = useAuthContext();

  const handleSignOut = async () => {
    await auth.signOut();
    localStorage.removeItem('recentLocations');
  };

  const menuItemsMap = new Map<string, HoneReportSummary[]>();
  menuItemsMap.set('P&L', []);
  menuItemsMap.set('Balance Sheet', []);
  menuItemsMap.set('Cash Flow', []);
  menuItemsMap.set('AP Aging', []);

  useReportsQuery(reports => {
    if (reports) {
      reports.forEach(report => {
        switch (report.type) {
          case HoneReportTypes.PLComparison:
            if (report.timeframe === HoneReportTimeframes.Weekly) {
              menuItemsMap.set('P&L', [...menuItemsMap.get('P&L')!, report]);
            } else if (report.timeframe === HoneReportTimeframes.Monthly) {
              menuItemsMap.set('P&L', [...menuItemsMap.get('P&L')!, report]);
            }
            break;
          case HoneReportTypes.BalanceSheet:
            menuItemsMap.set('Balance Sheet', [...menuItemsMap.get('Balance Sheet')!, report]);
            break;
          case HoneReportTypes.CashFlow:
            menuItemsMap.set('Cash Flow', [...menuItemsMap.get('Cash Flow')!, report]);
            break;
          case HoneReportTypes.IncomeStatement:
            menuItemsMap.set('P&L', [...menuItemsMap.get('P&L')!, report]);
            break;
          case HoneReportTypes.ApAging:
            menuItemsMap.set('AP Aging', [...menuItemsMap.get('AP Aging')!, report]);
            break;
        }
      });
    }
    return reports;
  });

  const menuItems = new Map([...menuItemsMap].filter(([k, v]) => v.length > 0));

  const { locationId: currentLocationId } = useParams();

  const { pathname } = useLocation();
  const currentLocation = useLocationsStore(state => state.currentLocation);

  const { isOwner, currentLocationAbilities } = useHoneLocationUsers();
  const { toggleSidenav } = useSidenavStore();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const shouldCollapse = true; //isMobile ? !isSidenavCollapsed : isSidenavCollapsed;

  const askRequestsCountForCurrentRole = useAskRequestsPendingCount();

  const canReadBookkeeperControls = abilityCan(
    currentLocationAbilities,
    HoneAbilityActions.read,
    HoneAbilitySubjects.Bookkeeper_Controls
  );

  const isAskRequestsEnabled =
    currentLocation?.askCode !== '' ||
    (askRequestsCount && Math.max(...Object.values(askRequestsCount).map(Number)) > 0);
  const menuItemsEnabled = Array.from(menuItems.keys());

  const payLinks: LocationConfig = {
    links: {
      paymentLink: currentLocation?.config?.links?.paymentLink ?? '',
      payroll: currentLocation?.config?.links?.payroll ?? '',
      v2: currentLocation?.config?.links?.v2 ?? '',
    },
  };

  // Adding additional checks to see if We are on the mobile version. If so, we disabled every report and only show dashboard, logout and Ask
  return (
    <div id="SidenavMenu" className={classNames('NavigationMenu', { 'NavigationMenu-closed': shouldCollapse })}>
      <div className="NavigationMenu-logo-user">
        <Link to={`/app/location/${currentLocationId}`}>
          <IconKS />
        </Link>
      </div>

      <ul className="NavigationMenu-group">
        <li className="NavigationMenu-groupItem">
          <Link
            key="link-dashboard"
            to={`/app/location/${currentLocationId}/dashboard`}
            onClick={() => {
              isMobile && toggleSidenav();
            }}
            title={'Dashboard'}
          >
            <SideNavIcon Icon={IconNbDash} selected={pathname.includes('dashboard')} title="Home" tooltip="Home" />
          </Link>

          {!isMobile &&
            menuItemsEnabled.map((key, idx) => {
              const menuItem = menuItems.get(key);

              if (!menuItem) return null;

              let currentReport = menuItem.find(it => it.title?.startsWith('Weekly P&L'));
              if (!currentReport) {
                currentReport = menuItem.find(it => it.timeframe === HoneReportTimeframes.Monthly);
              }
              if (!currentReport) {
                currentReport = menuItem.find(it => it.type === HoneReportTypes.IncomeStatement);
              }

              if (key === 'AP Aging') {
                currentReport = menuItem.find(it => {
                  return it.type === HoneReportTypes.ApAging;
                });
              }

              if (!currentReport) {
                return;
              }
              const reportType = currentReport.type;
              const Icon = MENU_ICONS[reportType as keyof typeof MENU_ICONS];
              let reportUrlTitle = reportTitleToUrl(key, true);

              if (reportUrlTitle === 'pl') {
                reportUrlTitle = currentReport.timeframe.toLowerCase().includes('ytd')
                  ? 'income-statement'
                  : currentReport.timeframe.toLowerCase();
              }

              const selected =
                pathname.includes(currentReport.id) ||
                pathname.includes(reportUrlTitle) ||
                (['income-statement', 'monthly', 'weekly'].some(v => pathname.includes(v)) &&
                  reportTitleToUrl(key, true) === 'pl');

              let to =
                reportTitleToUrl(key, true) === 'pl'
                  ? `/app/location/${currentLocationId}/report/${reportUrlTitle}?templateId=${currentReport.templateId}`
                  : `/app/location/${currentLocationId}/report/${reportUrlTitle}`;

              if (enableNewPlReportModal) {
                const urlParams = new URLSearchParams({});
                if (reportTitleToUrl(key, true) === 'pl') {
                  urlParams.set('templateId', currentReport.templateId!);
                  urlParams.set('chartCollapsed', 'true');
                  urlParams.set('type', 'P&L Comparison');
                  urlParams.set('difference', 'false');
                  urlParams.set('difference', 'false');
                  urlParams.set('reverse', 'false');
                  urlParams.set('budgetInclude', 'false');
                  urlParams.set('customDateRange', 'false');
                  urlParams.set('breakdownPeriods', 'false');

                  if (currentReport.timeframe === HoneReportTimeframes.Weekly) {
                    urlParams.set('timeframe', 'Week');
                    urlParams.set('comparisonType', 'Prior Period');
                    urlParams.set('comparisonPeriods', '4');
                    urlParams.set('compareEnabled', 'true');
                  } else {
                    const start = format(startOfMonth(new Date()), 'yyy-MM-dd');
                    const end = format(endOfMonth(new Date()), 'yyy-MM-dd');
                    urlParams.set('timeframe', 'Month');
                    urlParams.set('dateRange', `${start},${end}`);
                  }
                }

                to = `/app/location/${currentLocationId}/report/${reportUrlTitle}?${urlParams.toString()}`;
              }

              return (
                <Link key={`link-${idx}`} to={to} onClick={() => isMobile && toggleSidenav()}>
                  <SideNavIcon
                    Icon={Icon}
                    selected={selected}
                    title={closedTitles[key as keyof typeof closedTitles]}
                    beta={isBeta(key)}
                    tooltip={key}
                  />
                </Link>
              );
            })}
          {enableForecasting && (isOwner || canReadBookkeeperControls) && (
            <Link
              key="link-forecast"
              to={`/app/location/${currentLocationId}/forecast`}
              onClick={() => {
                isMobile && toggleSidenav();
              }}
              title={'Forecast'}
            >
              <SideNavIcon
                Icon={IconForecast}
                selected={pathname.includes('forecast')}
                title="Forecast"
                tooltip="Forecast"
                beta={isBeta('Forecast')}
              />
            </Link>
          )}
          {!isMobile && canReadBookkeeperControls && <SideNavAccrualsLink {...{ pathname, isMobile, toggleSidenav }} />}
        </li>
        <li className="NavigationMenu-groupItem">
          {isAskRequestsEnabled && (
            <Link
              title={UIAskRequestsTitles.ASK_REQUESTS}
              to={`/app/location/${currentLocationId}/ask-requests?activeTab=Open`}
              onClick={() => {
                isMobile && toggleSidenav();
              }}
            >
              <SideNavIcon
                Icon={IconNbAsk}
                selected={pathname.includes('/ask-requests')}
                title={closedTitles[UIAskRequestsTitles.ASK_REQUESTS as keyof typeof closedTitles]}
                tooltip="Ask Requests"
                badge={askRequestsCountForCurrentRole}
                beta={isBeta('Ask Requests')}
              />
            </Link>
          )}
          {!isMobile && canReadBookkeeperControls && (
            <Link
              key={`link-bookkeeper`}
              to={`/app/location/${currentLocationId}/bookkeeper`}
              onClick={() => {
                isMobile && toggleSidenav();
              }}
              title={'Bookkeeper'}
            >
              <SideNavIcon
                Icon={IconNbBk}
                selected={pathname.includes('/bookkeeper')}
                title={closedTitles['Bookkeeper' as keyof typeof closedTitles]}
                tooltip="Accountant"
                beta={isBeta('Bookkeeper')}
              />
            </Link>
          )}

          {!isMobile && (
            <>
              {payLinks.links && payLinks.links.v2 && (
                <Link key="link-v2" to={payLinks.links.v2} target="_blank">
                  <SideNavIcon
                    Icon={IconCalendarNb}
                    selected={pathname.includes('/calendar')}
                    title={closedTitles['Calendar' as keyof typeof closedTitles]}
                    tooltip="Calendar"
                    beta={isBeta('Calendar')}
                  />
                </Link>
              )}
              {payLinks.links && payLinks.links.payroll && (
                <Link key="link-payroll" to={payLinks.links.payroll} target="_blank">
                  <SideNavIcon
                    Icon={IconPayrollNb}
                    selected={pathname.includes('/payroll')}
                    title={closedTitles['Payroll' as keyof typeof closedTitles]}
                    tooltip="Payroll"
                    beta={isBeta('Payroll')}
                  />
                </Link>
              )}
            </>
          )}
          {!isMobile && !isEmpty(currentLocationAbility) && (
            <Can I="update" a="Internal Controls" ability={currentLocationAbility}>
              <Link key="link-bkpr-admin" to={`/app/location/${currentLocationId}/admin`}>
                <SideNavIcon
                  Icon={IconAdmin}
                  selected={pathname.includes('/admin')}
                  title={closedTitles['Bkpr Admin' as keyof typeof closedTitles]}
                  tooltip="Accountant Admin"
                  beta={isBeta('Bkpr Admin')}
                />
              </Link>
            </Can>
          )}
        </li>
      </ul>
      <div className="NB-logout" onClick={handleSignOut}>
        <IconNbLogout />
      </div>
      {isMobile && <div className="app-version">v{__APP_VERSION__}</div>}
    </div>
  );
}

export default Sidenav;

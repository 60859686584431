import { makeRemoteTemplateRanges } from '../../main/factories/usecases/reports/remote-get-templates-ranges';
import { useLocationsStore } from '../useLocationsStore';
import { useQuery } from '@tanstack/react-query';

const templatesRangesService = makeRemoteTemplateRanges();

export const useTemplatesRangesQuery = () => {
  const currentLocation = useLocationsStore.getState().currentLocation;
  const currentLocationId = currentLocation?.id;

  return useQuery({
    queryKey: ['templatesRanges', currentLocationId],
    queryFn: async () => {
      if (!currentLocationId) return;
      // return (await templatesRangesService.get({ locationId: currentLocationId })) as TemplateRange[]
      return (await templatesRangesService.get({ locationId: currentLocationId })) as TemplateRange[];
    },
    enabled: !!currentLocation,
  });
};

import { useLocationsStore } from '../../../../hooks/useLocationsStore';
import { analyzeVariance } from './varianceAnalysis';
import { processFlaggedCells } from './flaggedTrx';
import {
  buildTitle,
  reportRange,
  isLiveReport,
  inferColumnType,
  series,
  generateFilteredId,
  findDataElementByTitle,
} from './utils';
import { getReportCapabilities } from './capabilities';
import { detectAnomaly } from './detectAnomalies';
import { createTotalColumn, createChartsValues } from './utilityData';
import { Column, VizHelpers, TransformedData, Location, Anomaly, FlaggedCellProps, Amounts, Accounts } from './types';
import { UIFlaggedTransaction } from 'types';
import { Location as KsLocation } from '@hone-automation/common';

// This is going to be a streamlined version of the NT parser
// It will be used to parse the NT report and create the intermediate data structure that will be used in all exports.

interface createTransformedDataProps {
  report: NestedHoneReport;
  useComputedColumns?: boolean;
  periodReporting?: boolean;
  currentLocation?: KsLocation;
}

interface initializeTransformDataProps extends createTransformedDataProps {
  isMultiLocation?: boolean;
  consolidated: boolean;
  periodReporting: boolean;
  isTotal: boolean;
  isDifference: boolean;
}

const initializeTransformData = (props: initializeTransformDataProps): TransformedData | null => {
  const {
    report,
    useComputedColumns,
    periodReporting,
    isMultiLocation,
    currentLocation,
    consolidated,
    isTotal,
    isDifference,
  } = props;
  if (!report || !currentLocation) return null;

  const transformedData: TransformedData = {
    name: buildTitle(report, reportRange(report)),
    timeFrame: report.timeframe,
    type: report.type,
    capabilities: getReportCapabilities(report.type),
    range: reportRange(report),
    currency: 'USD',
    locationId: currentLocation?.id,
    locationName: currentLocation?.name,
    columns: [],
    locations: [],
    budgetAvailable: false,
    difference: !useComputedColumns || isDifference,
    liveReport: isLiveReport(report) > -1, // --------> add a new property to hold the live column, it should always be the 1st one, but just in case
    multiLocation: isMultiLocation,
    consolidated,
    periodReporting: periodReporting,
    totalColumn: !useComputedColumns || isTotal,
    utilityData: {
      totalColumn: { type: [{ type: 'Direct' }], data: [] },
      differenceColumn: { type: [{ type: 'Direct' }], data: [] },
      totalRow: [],
    },
  };
  return transformedData;
};

const createTransformedData = ({
  report,
  useComputedColumns,
  periodReporting = false,
  currentLocation,
}: createTransformedDataProps): TransformedData | null => {
  if (!report || !currentLocation) {
    console.log('(silent error) NT:createTransformedData: Missing report or currentLocation');
    return null;
  }

  const locations = new Set(report?.dates.map(date => date.location?.id || date.groupedDates).filter(Boolean));
  const isMultiLocation = locations.size > 1;

  const transformedData: TransformedData = report?.dates.reduce((acc: any, date: any, index: number) => {
    const locationId = date.location?.id;
    const locationName = date.location?.name;
    const reportId = date.reportId;
    const consolidated = !!date.groupedDates;

    // Skip this element if there's no location. This case was to solve difference column, but we will probably skip it soon
    const inferColumnTypeResult = inferColumnType(date, locationId, locationName, useComputedColumns);
    if (!inferColumnTypeResult) {
      return acc;
    }
    const isTotal = inferColumnTypeResult.isTotal;
    const isDifference = inferColumnTypeResult.isTotal;

    if (index === 0) {
      acc = initializeTransformData({
        report,
        currentLocation,
        useComputedColumns,
        isMultiLocation,
        consolidated,
        periodReporting,
        isTotal,
        isDifference,
      });
    } else {
      acc.totalColumn = acc.totalColumn || isTotal;
    }

    if (date.groupedDates) {
      date.groupedDates.forEach((groupedDate: any) => {
        const locationId = groupedDate.location?.id;
        const locationName = groupedDate.location?.name;
        const reportId = groupedDate.reportId;

        if (locationId && locationName && !acc.locations.some((loc: Location) => loc.id === locationId)) {
          acc.locations.push({ id: locationId, name: locationName, reportId });
        }
      });
    } else if (locationId && locationName && !acc.locations.some((loc: Location) => loc.id === locationId)) {
      acc.locations.push({ id: locationId, name: locationName, reportId });
    }
    // Not explicit type so probably is not a New P&L
    let columnType = date.type || (date.start && date.end) ? 'Data' : 'Direct';
    if (date.type === 'Difference' || date.type === 'Total') columnType = 'Direct';

    // Fixed column behavior. If the date.start of the report column is Total or the date.start is empty ("Difference") we activate the last column fixed
    const baseName = date.start === '' ? 'Difference' : date.start;

    if (date.type === 'Budget') {
      // Identifying Budget Column if any to prepare the Budget mergee
      acc.budgetAvailable = true;
      const lastColumn = acc.columns[acc.columns.length - 1];
      const newDate = { start: baseName, end: date.end || null };
      if (lastColumn.date.start === newDate.start && lastColumn.date.end === newDate.end) {
        // If budget exists we add it to the types of the last column
        acc.columns[acc.columns.length - 1].type.push({ type: 'Budget', index });
      }
    } else {
      const newColumn = {
        name: isMultiLocation && !consolidated ? locationName : baseName,
        date: { start: baseName, end: date.end || null },
        type: [{ type: columnType, index }],
        data: [],
        total: isTotal,
        compoundColumn: !useComputedColumns && (isTotal || isDifference),
        isLocation: isMultiLocation,
        periodReporting: periodReporting,
      };

      acc.columns.push(newColumn);
    }

    return acc;
  }, {});
  return transformedData;
};

type ParseReportNTProps = {
  report: NestedHoneReport;
  useComputedColumns?: boolean;
  periodReporting?: boolean;
  flaggedTransactions?: UIFlaggedTransaction[];
  currentLocation?: KsLocation;
  trace?: boolean;
};

export const parseReportNT = ({
  report,
  useComputedColumns,
  periodReporting = false,
  flaggedTransactions,
  currentLocation,
  trace = true,
}: ParseReportNTProps): TransformedData | null => {
  if (trace) {
    console.log('report', report);
    console.log('flaggedTransactions', flaggedTransactions);
    console.time('parseReportNT');
  }

  if (!report || !currentLocation) {
    console.log('(silent error) NT:parseReportNT: Missing report or currentLocation');
    return null;
  }

  // Create the transformed data object
  const transformedData: TransformedData | null = createTransformedData({
    report,
    useComputedColumns,
    currentLocation,
    periodReporting,
  });

  if (!transformedData) {
    console.log('(silent error) NT:: Failed to parse report');
    return null;
  }
  transformedData.flaggedCells = processFlaggedCells(report, flaggedTransactions);
  // Create the accounts column and fill in the data property of every column after we mapped budget properly
  const accounts: Column = createAccountsColumn(report, transformedData);
  // Adding the baseValues where the percentages come from to the amounts, so we can create total and difference percentages accurately
  applyBaseValues(transformedData);

  // If we pass the transactions we will process the flagged cells
  if (flaggedTransactions) transformedData.flaggedCells = processFlaggedCells(report, flaggedTransactions);

  // Adding Utility Data
  transformedData.utilityData.totalColumn = createTotalColumn(transformedData);
  transformedData.utilityData.differenceColumn = createTotalColumn(transformedData, true);
  transformedData.utilityData.chartData = createChartsValues(transformedData);

  // We add the accounts column to the beginning of the columns array, only after we have processed the rest of data transformations
  transformedData.columns.unshift(accounts);

  if (trace) {
    console.log('transformedData', transformedData);
    console.timeEnd('parseReportNT');
  }
  return transformedData;
};

const applyBaseValues = (transformedData: TransformedData): any => {
  // Adding the baseValues where the percentages come from to the amounts, so we can create total and difference percentages accurately
  transformedData.columns
    .filter((column: Column) => !column.compoundColumn)
    .forEach((column: any, columnIndex: number) => {
      column.data.forEach((amount: any, rowId: number) => {
        const baseValue = findDataElementByTitle(column.data, amount.baseRef);
        if (baseValue) {
          amount.baseAmount = baseValue.amount;
          amount.cPercent = amount.amount / amount.baseAmount;
        }
      });
    });
};

const createAccountsColumn = (report: NestedHoneReport, transformedData: TransformedData): Column => {
  // Adding the accounts column
  const accounts: Column = {
    name: 'Accounts',
    type: [
      {
        type: 'Account',
      },
    ],
    data: [],
    isLocation: transformedData.multiLocation && !transformedData.consolidated,
    periodReporting: transformedData.periodReporting,
    date: transformedData ? transformedData.columns[0].date : null,
    compoundColumn: false, // Accounts column is not a compound column
  };

  // All this below is to get the budget data from dates and mapping it properly into the columns data array
  accounts.data = getDataAccounts(report, transformedData);
  accounts.data?.forEach(account => {
    transformedData.columns.forEach((column: any, columnIndex: number) => {
      const tmpAmount = account.amounts[column.type[0].index];
      column.type.forEach((type: any, typeIndex: number) => {
        if (type.type === 'Budget') {
          // This is to get the budget data from dates and mapping it properly into the columns data array
          tmpAmount.budget = account.amounts[column.type[typeIndex].index].amount;
          tmpAmount.budgetPercent = account.amounts[column.type[typeIndex].index].percent;
          tmpAmount.difference = tmpAmount.amount - tmpAmount.budget;
          tmpAmount.variance = tmpAmount.budget !== 0 ? tmpAmount.difference / tmpAmount.budget : 0;
          tmpAmount.varianceAnalisys = analyzeVariance(account.amountTitle, tmpAmount.variance);
        }
      });
      column.data && column.data.push(tmpAmount);
    });
  });

  return accounts;
};

// Extract the accounts data from the report
export const getDataAccounts = (report: NestedHoneReport, transformedData: TransformedData): Accounts[] | undefined => {
  // Let's analyze all levels of the tree and get the lowest one. If it's not 0 then calculate the level difference so we can apply below
  // the correct level to the accounts
  const lowestLevel = report?.sections.reduce((minLevel: number, section: any) => {
    const level = section.level;
    if (section.display && section.display !== 'hidden' && section.display !== 'empty') {
      return level < minLevel ? level : minLevel;
    }
    return minLevel;
  }, Infinity);

  return report?.sections.flatMap((item: any, sectionIndex: number) => {
    const flattenSection = (
      section: any,
      parent: string | null = null,
      isLastChild: boolean = false,
      parentPath: string[] = []
    ): Accounts[] => {
      //GL extraction
      const glCodeMatch = section.title.match(/\b\d{4,5}\b/);
      const glCode = section.glCode || (glCodeMatch ? glCodeMatch[0] : null);
      const title = glCodeMatch ? section.title.replace(/\b\d{4}\b/, '').trim() : section.title;

      //Style extraction
      const displayClass = section.display?.split('_')[0];
      const isBigHeader = displayClass === 'header' && section.level === 0;
      const isTotal = displayClass === 'total';
      const isHeader = section.display === 'total_2' || (displayClass === 'header' && section.level > 0);

      // If display value is a header and all amounts in data are 0 or null then we do not display the values of that row
      const noValuesToShow =
        (isBigHeader || isHeader) &&
        section.data.length > 0 &&
        section.data.every((item: { amount: number | null }) => item.amount === 0 || item.amount === null);

      const filteredId = generateFilteredId(section.id, title);

      const account: Accounts = {
        id: `${filteredId}_@_${sectionIndex}`, // Some indexs are not unique
        cellID: `${filteredId}_@_${sectionIndex}_-x-_0`,
        amountTitle: title,
        percentTitle: section.titlePerc,
        baseRef: section.titlePerc !== '' ? section.titlePerc : undefined,
        level: section.level - lowestLevel,
        glCode: glCode,
        parent: parent,
        path: [...parentPath, title],
        display: section.display,
        children: section.sections
          ? section.sections.map((child: any) => `${generateFilteredId(child.id, child.title)}_@_${sectionIndex}`)
          : undefined,
        vizHelpers: {
          lastItem: isLastChild,
          isTotal: isTotal,
          isHeader: isHeader,
          isBigHeader: isBigHeader,
          isExpanded: true,
          isSelected: false,
          noValuesToShow: noValuesToShow,
        },
      };

      const dataR = parseDataRows({
        section,
        transformedData,
        path: [...parentPath, title],
        sectionIndex,
        filteredId,
        title,
        vizHelpers: account.vizHelpers,
      });

      account.amounts = dataR.amounts;
      account.anomalies = dataR.anomaly;
      let result: Accounts[] = [account];

      // If we have chiildren, we need to flatten them too
      if (section.sections && section.sections.length > 0) {
        section.sections.forEach((child: any, index: number) => {
          // We detect the last child so we can display the hierarchy correctly
          const isLastChildOfParent = index === section.sections.length - 1;
          result = result.concat(flattenSection(child, filteredId, isLastChildOfParent, [...parentPath, title]));
        });
      }

      return result;
    };

    // Filtering out hidden and empty items
    return item.display !== 'hidden' && item.display !== 'empty'
      ? flattenSection(item).filter(account => account.display !== 'hidden' && account.display !== 'empty')
      : [];
  });
};

type ParseDataRowsProps = {
  section: any;
  transformedData: TransformedData;
  filteredId: string;
  path: string[];
  sectionIndex: number;
  title: string;
  vizHelpers: VizHelpers;
};

const parseDataRows = (dataRowsObject: ParseDataRowsProps) => {
  const { section, transformedData, filteredId, path, sectionIndex, title, vizHelpers } = dataRowsObject;
  const amounts: Amounts[] = [];

  // We look for anomalies in the data, both in amounts and transactions
  const anomaly = detectAnomaly(series(section.data, 'amount'));
  const trxAnomaly = detectAnomaly(series(section.data, 'dataRows'));
  section.data.forEach((data: any, index: number) => {
    const accountId = `${filteredId}_@_${sectionIndex}`;
    const trxFlagged = transformedData.flaggedCells?.filter((trx: FlaggedCellProps) => {
      // If the cell has transactions we check the flagged elements vs transaction values (type = undefined)
      if (data.dataRows && data.dataRows.length > 0) {
        return data.dataRows.some((dR: any) => {
          return (
            trx.transaction?.trxType === dR.type &&
            trx.period === dR.txnDate &&
            trx.amount === dR.amount &&
            trx.transaction?.memo === dR.description
          );
        });
      }
      // Otherwise we check the flagged elements vs the cell itself (type = Flagged Cell)
      return (
        trx.account === section.title &&
        transformedData.columns[index] &&
        transformedData.columns[index].date?.start === trx.period
      );
    });
    //if (trxFlagged && trxFlagged.length>0) console.log('trxFlagged', section.title, trxFlagged);
    const amount = getDataAmountsColumn({
      data,
      date: (transformedData.columns[index] && transformedData.columns[index].date?.start) || '',
      title,
      path,
      baseRef: section.titlePerc !== '' ? section.titlePerc : undefined,
      index,
      accountId,
      vizHelpers: vizHelpers,
      anomaly,
      trxAnomaly,
      trxFlagged,
    });
    amounts.push(amount);
  });

  return { anomaly, amounts };
};

const getDataAmountsColumn = ({
  data,
  accountId,
  index,
  ...rest
}: {
  data: any;
  accountId: string;
  index: number;
  date: string;
  title: string;
  path: string[] | undefined;
  baseRef: string;
  vizHelpers: VizHelpers;
  anomaly: Anomaly;
  trxAnomaly: Anomaly;
  trxFlagged?: FlaggedCellProps[];
}): Amounts => {
  return {
    ...rest,
    ...data,
    amountAVG: data.amtAvg,
    percent: data.perc,
    percentAVG: data.percAvg,
    cellID: `${accountId}_-x-_${index + 1}`,
    baseAmount: data.percent ? data.amount / data.perc : data.amount,
    transactions: data.dataRows.length || 0,
    id: accountId,
    index,
  };
};
